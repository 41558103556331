const AUTH_REGISTRATION_SCHEMA = schema.object({
  email: schema
    .string({
      required_error: localeRuValidate.required,
    })
    .email({
      message: localeRuValidate.email,
    }),
  password: schema
    .string({
      required_error: localeRuValidate.required,
    })
    .min(8, {
      message: localeStringReplacement(localeRuValidate.lengthMin, {
        items: "символов",
        length: "8",
      }),
    }),
});

type AuthRegistrationSchemaInfer = SchemaInfer<typeof AUTH_REGISTRATION_SCHEMA>;

type AuthRegistrationFields = FormFields<AuthRegistrationSchemaInfer>;

type AuthRegistrationForm = RegistrationPayload;

export { AUTH_REGISTRATION_SCHEMA };
export type { AuthRegistrationFields, AuthRegistrationForm };
