const useAuthRegistrationForm = () => {
  const { defineField, errors, handleSubmit, setErrors } = useForm<AuthRegistrationForm>({
    validationSchema: toTypedSchema(AUTH_REGISTRATION_SCHEMA),
  });

  const fields: AuthRegistrationFields = {
    email: defineField("email"),
    password: defineField("password"),
  };

  const loading = ref(false);

  const submit = handleSubmit(async (formData) => {
    loading.value = true;

    const { body, success } = await useAuthRegistrationDto(formData);

    if (success && body.data) {
      const { setAuthToken } = useAuthStore();
      const { setRefreshTokenCookie } = useAuthToken();

      setAuthToken(body.data);
      setRefreshTokenCookie(body.data);

      const { item } = await useGetClientDto();

      if (item.value) {
        const { setClient } = useClientStore();
        const { closeModal } = useModalStore();

        setClient(item.value);
        loading.value = false;
        closeModal();
        await navigateTo("/client/projects");
      }
    }

    if (!success) {
      setErrorsFields.rest(setErrors, body);
    }

    loading.value = false;
  });

  return {
    errors,
    fields,
    handleSubmit: submit,
    loading,
  };
};

export { useAuthRegistrationForm };
