const useAuthRegistrationDto = async (payload: RegistrationPayload) => {
  const response = await useFetchData<FetchData<AuthToken>>("/auth/registration", {
    body: payload,
    method: "POST",
  });

  return {
    ...response,
  };
};

export { useAuthRegistrationDto };
